@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./../fonts/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
