@import './fonts.css';

body {
  margin: 0;
}

.ant-input-prefix .anticon {
  margin-inline-end: 4px;
}

.ant-table-thead .long-column {
  min-width: 208px;
  max-width: 208px;
  word-break: break-all;
  overflow-wrap: break-word;
}

.ant-table-tbody .long-column {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 208px;
  min-width: 208px;
  overflow: hidden;
}

.ant-table-thead .short-column {
  max-width: 70px;
  min-width: 70px;
}

.ant-table-tbody .short-column {
  max-width: 70px;
  min-width: 70px !important;
}

.ant-table-tbody tr.archived-row {
  background-color: #0000000a;
}

.ant-table-tbody tr.archived-row td.ant-table-cell-fix-right {
  background-color: #ececec;
}

.ant-table-tbody tr.archived-row td.ant-table-cell-fix-left {
  background-color: #ececec;
}

.ant-table-tbody tr.archived-row td {
  background-color: #ececec;
}

.no-right-padding .ant-modal-content {
  padding-right: 0;
}

.ant-table-cell-fix-left-last .ant-table-filter-trigger {
  margin-inline-end: -2px !important;
}

.ant-table-tbody tr.archived-row .ant-table-cell-row-hover {
  background-color: #e1e1e1 !important;
}

.windows-os h1,
.windows-os h2,
.windows-os h3,
.windows-os h4,
.windows-os h5,
.windows-os h6,
.windows-os p,
.windows-os span,
.windows-os .ant-typography,
.windows-os input::placeholder,
.windows-os .ant-select-selection-item {
  line-height: 1.5;
  vertical-align: text-top;
}

.windows-os .ant-input {
  line-height: 1.2;
}

.windows-os .ant-avatar-icon {
  vertical-align: middle !important;
}

.windows-os .ant-input,
.windows-os input::placeholder {
  padding-top: 8px !important;
}

.windows-os .ant-input-affix-wrapper .ant-input {
  padding-top: 4px !important;
}

.windows-os .ant-input-number-input-wrap {
  line-height: 1.2;
}

.windows-os .ant-input-number-input-wrap input {
  padding-top: 5px !important;
  padding-bottom: 0;
  min-height: 30px;
}

.windows-os .ant-select-item-option-content {
  padding-top: 2px;
}

.windows-os .ant-select-selection-item {
  padding-top: 2px !important;
}

.windows-os .ant-picker-input input {
  padding-top: 5px !important;
}

.windows-os .ant-btn span:not(.ant-btn-icon) {
  line-height: 1.2;
}

.windows-os .ant-btn.language-switch span {
  line-height: 1.5;
}

.windows-os .ant-radio-wrapper > span:not(.ant-radio) {
  padding-top: 5px;
}

.windows-os .ant-select-selection-item,
.windows-os .ant-btn span.ant-btn-icon,
.windows-os .ant-input-number-group-addon {
  padding-top: 2px;
}

.windows-os .ant-picker-input input {
  padding-top: 5px !important;
}

.windows-os .ant-btn {
  align-items: self-end;
}

.windows-os .ant-btn-icon {
  height: 25px;
}

.windows-os .ant-menu-title-content,
.windows-os .ant-dropdown-menu-title-content {
  padding-top: 5px;
}

.windows-os .ant-pagination-item,
.windows-os .ant-tag,
.windows-os .ant-table-column-title,
.windows-os .ant-select-selection-item-content,
.windows-os a {
  padding-top: 2px;
}

.windows-os .ant-badge-status-dot {
  margin-top: 5px;
}

.windows-os .client-name {
  padding-top: 3px;
}
